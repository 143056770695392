import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyCodeParams } from "../../utils/currencyOptions";
import { fix2, fix8 } from "../../utils/fixers";
import { UserProfile } from "../UserProfile";
import { Text } from "../../utils/Text";
import "./index.scss";
import { audioPlay, registerAudio } from "../../utils/media";
import { AUDIO } from "../../constants/audio";

const NoBetsComponent = () => (
  <>
    <div
      className="doubleUsersBet__userbet"
      style={{ textAlign: "center", display: "flex", justifyContent: "center" }}
    >
      <div style={{ color: "#888", marginBottom: "36px", marginTop: "-24px" }}>
        <Text tid="DOUBLE.DOUBLE_TAB.NO_BETS" />
      </div>
    </div>
  </>
);

const UserComponent = ({ b, isFiat }) => (
  <div className="doubleUsersBet__userbet">
    <div className="doubleUsersBet__userbet--name">
      <UserProfile user={b?.user}>
        <img src={b?.user?.photo} alt="" className="doubleUsersBet__avatar" />
      </UserProfile>
      <p>{b?.user?.nickname || ""}</p>
    </div>
    <p className="doubleUsersBet__userbet--bet">
      {}
      {isFiat ? fix2(b?.sumUsdt) : fix8(b.sumToken || 0)}
      {isFiat ? (
        <React.Fragment>
          $<img src={currencyCodeParams[b?.tokenCode]?.icon} alt="USDT" />
        </React.Fragment>
      ) : (
        <img src={currencyCodeParams[b?.tokenCode]?.icon} alt="USDT" />
      )}
    </p>
  </div>
);

const DoubleUsersBet = () => {
  const { gameData, isFiat } = useSelector(({ game, balance }) => ({
    gameData: game?.data,
    isFiat: balance.isFiat,
  }));

  const [audioIsAvailable, setAudioIsAvailable] = useState(false);

  useEffect(() => {
    if (gameData?.bets?.length !== undefined && gameData?.type === "DOUBLE") {
      const lth = gameData?.bets?.length;
      if (audioIsAvailable && lth) {
        const audio = registerAudio(AUDIO.BET_MAKE);
        audioPlay(audio);
      }
      setAudioIsAvailable(true);
    }
  }, [gameData?.bets?.length, gameData?.type]);

  const bets = [...(gameData?.bets || [])].reverse();

  const redBets = bets?.filter((b) => b.color === "RED");
  const greenBets = bets?.filter((b) => b.color === "ZERO");
  const blackBets = bets?.filter((b) => b.color === "BLACK");

  const redBetsTotal = redBets?.reduce?.((acc, c) => acc + c.sumUsdt, 0);
  const greenBetsTotal = greenBets?.reduce?.((acc, c) => acc + c.sumUsdt, 0);
  const blackBetsTotal = blackBets?.reduce?.((acc, c) => acc + c.sumUsdt, 0);

  return (
    <div className="doubleUsersBet">
      <div className="doubleUsersBet__container">
        <div className="doubleUsersBet__red-bg">
          <div className="doubleUsersBet__user">
            <div className="doubleUsersBet__user--title">
              <p>
                <Text tid="DOUBLE.DOUBLE_TAB.TOTAL_BETS" />:
              </p>
              <p className="red-c">{fix2(redBetsTotal || 0)}$</p>
            </div>
            <div
              className={`${
                redBets?.length ? "doubleUsersBet__userbet-container" : ""
              }`}
            >
              {!redBets?.length && <NoBetsComponent />}
              {redBets?.map?.((b) => (
                <UserComponent b={b} isFiat={isFiat} />
              ))}
            </div>
          </div>
        </div>
        <div className="doubleUsersBet__green-bg">
          <div className="doubleUsersBet__user">
            <div className="doubleUsersBet__user--title">
              <p>
                <Text tid="DOUBLE.DOUBLE_TAB.TOTAL_BETS" />:
              </p>
              <p className="green-c">{fix2(greenBetsTotal || 0)}$</p>
            </div>
            <div
              className={`${
                greenBets?.length ? "doubleUsersBet__userbet-container" : ""
              }`}
            >
              {!greenBets?.length && <NoBetsComponent />}
              {greenBets?.map?.((b) => (
                <UserComponent b={b} isFiat={isFiat} />
              ))}
            </div>
          </div>
        </div>
        <div className="doubleUsersBet__black-bg">
          <div className="doubleUsersBet__user ">
            <div className="doubleUsersBet__user--title">
              <p>
                <Text tid="DOUBLE.DOUBLE_TAB.TOTAL_BETS" />:
              </p>
              <p className="black-c">{fix2(blackBetsTotal || 0)}$</p>
            </div>
            <div
              className={`${
                blackBets?.length ? "doubleUsersBet__userbet-container" : ""
              }`}
            >
              {!blackBets?.length && <NoBetsComponent />}
              {blackBets?.map?.((b) => (
                <UserComponent b={b} isFiat={isFiat} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleUsersBet;
