import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useChatTrigger } from "../../hooks/use-chat-trigger";
import { audioPlay, registerAudio } from "../../utils/media";
import { GAME_STATUSES } from "../../constants/games";
import { classicConnect } from "../../actions/game";
import { AUDIO } from "../../constants/audio";
import ClassicGameView from "./View";

const ClassicGame = ({
  handleBidPopUpClick,
  isChatOpened,
  setCurrency,
  currency,
}) => {
  const { socket: st } = useSelector(({ socket }) => ({ socket }));
  const { isOpenTrigger } = useChatTrigger({ isChatOpened });

  const { isConnecting, gameData, isFiat, user, balanceData } = useSelector(
    ({ game, user: userData, balance }) => ({
      isConnecting: game.connecting,
      user: userData?.data?.data,
      balanceData: balance.data,
      isFiat: balance.isFiat,
      gameData: game.data,
    })
  );

  const dispatch = useDispatch();

  const [audioIsAvailable, setAudioIsAvailable] = useState(false);

  useEffect(() => {
    if (gameData?.bets?.length !== undefined && gameData?.type === "CLASSIC") {
      const lth = gameData?.bets?.length;
      if (audioIsAvailable && lth) {
        const audio = registerAudio(AUDIO.BET_MAKE);
        audioPlay(audio);
      }
      setAudioIsAvailable(true);
    }
  }, [gameData?.bets?.length, gameData?.type]);

  useEffect(() => {
    if (gameData?.type && gameData?.type !== "CLASSIC")
      dispatch(classicConnect({ socket: st }));
  }, []);

  useEffect(() => {
    if (balanceData?.length && !currency) {
      setCurrency(balanceData[0]);
    }
  }, [balanceData?.length]);

  if (isConnecting || !gameData?._id) return <ClassicGameView loading />;

  const isGameFailed = gameData.status === GAME_STATUSES.FAILED;

  return (
    <ClassicGameView
      handleBidPopUpClick={handleBidPopUpClick}
      isOpenTrigger={isOpenTrigger}
      isChatOpened={isChatOpened}
      isGameFailed={isGameFailed}
      members={gameData.members}
      setCurrency={setCurrency}
      bets={gameData.bets}
      currency={currency}
      isFiat={isFiat}
      user={user}
    />
  );
};

export default ClassicGame;
