import styled from "styled-components";
import React, { useEffect } from "react";
import { disableScroll, enableScroll } from "../../utils/scroll";

export const TechWorks = () => {
  useEffect(() => {
    disableScroll();
    return () => enableScroll();
  }, []);

  return (
    <Wrapper className="techworks">
      <Blur>
        <Container>
          <Body>
            <Message>
              <Title>Внимание!</Title>
              <p>На сайте ведутся</p>
              <p>технические работы</p>
            </Message>
            <Icon>
              <img src="technical-works.png" alt="technical works" />
            </Icon>
          </Body>
        </Container>
      </Blur>
    </Wrapper>
  );
};

const Title = styled.div`
  margin-bottom: 6px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #d56e00;
`;

const Body = styled.div`
  align-items: center;
  margin-bottom: 24px;
  margin-top: 16px;
  font-size: 16px;
  display: flex;
  gap: 24px;
`;

const Icon = styled.div`
  img {
    opacity: 0.85;
    height: 72px;
    width: 72px;
  }
`;

const Message = styled.div`
  text-align: center;
  font-weight: 500;
  p {
    opacity: 0.85;
  }
`;

const Blur = styled.div`
  background-color: rgba(0, 0, 0, 1);
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0.8;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  backdrop-filter: blur(24px);
  position: fixed;
  z-index: 99999;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 24px 48px;
  margin-top: -15%;
`;
