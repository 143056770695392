/* eslint-disable jsx-a11y/media-has-caption */
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { audioPlay, registerAudio } from "../../utils/media";
import { GAME_STATUSES } from "../../constants/games";
import { getRandomNumber } from "../../utils/random";
import { AUDIO } from "../../constants/audio";

export const RouletteMediaView = ({ children }) => {
  const { gameStatus, gameTimer } = useSelector(({ game }) => ({
    gameStatus: game.data.status,
    gameTimer: game.data.timer,
  }));

  const [isAlreadyInGame, setIsAlreadyInGame] = useState(false);
  const [currentUser, setCurrentUser] = useState(5);
  const [trigger, setTrigger] = useState(false);
  const [audio, setAudio] = useState(null);

  const arrowIcon = document.querySelector(".roulette__arrow-icon");
  const arrowCenter = (arrowIcon?.getBoundingClientRect?.()?.left || 0) + 25;

  useEffect(() => {
    const a = registerAudio(AUDIO.SPIN);
    setAudio(a);
  }, []);

  useEffect(() => {
    const _class = ".roulette__user-avatar-key";
    const currUserDoc = document.querySelector(`${_class}-${currentUser}`);
    const currUserLeft = currUserDoc?.getBoundingClientRect?.()?.left;
    if (currUserLeft <= arrowCenter) {
      if (audio) {
        const volume = getRandomNumber(45, 75) / 100;
        audio.volume = volume;
        audio.pause();
        audio.currentTime = 0;
        audioPlay(audio);
      }

      setCurrentUser((pr) => pr + 1);
    }
  }, [trigger]);

  useEffect(() => {
    if (gameStatus === GAME_STATUSES.SPIN && isAlreadyInGame) {
      const t = setInterval(() => setTrigger((prev) => !prev), 10);
      return () => clearInterval(t);
    }

    if (gameStatus === GAME_STATUSES.RECRUITED) setIsAlreadyInGame(true);

    return () => clearInterval();
  }, [gameStatus, gameTimer]);

  return children;
};
